(<template>
  <div class="payment"
       :class="{'private-customer-payment': !userIsEnterpriseMember}">
    <page-data-section :title="sectionTitle"
                       :progress="progressActive"
                       class="payment__settings">
      <!--   Payment Method Type Tiles   -->
      <payment-method-options class="payment-options" />
      <section class="payment__content-wrapper">
        <div v-if="paymentMethods.length"
             class="payment__payment-methods">
          <h3 v-if="userIsEnterpriseMember"
              class="payment__table-title">{{ defaultPaymentMethodTitle }}</h3>
          <default-payment-table :payment-method="defaultPaymentMethod"
                                 :cols-list="paymentsCols"
                                 :progress="progressPaymentsActive"
                                 :can-change="canChangeDefault"
                                 class="payment__table payment__table--default"
                                 @edititem="editPayment"
                                 @deleteitem="onPaymentMethodDeleteClick" />
          <h3 v-if="userIsEnterpriseMember"
              class="payment__table-title">{{ allPaymentMethodsTitle }}</h3>
          <ent-setting-table :items-list="paymentMethodsExcludeDefault"
                             :cols-list="paymentsCols"
                             :progress="progressPaymentsActive"
                             :can-change="true"
                             :can-set-default="canChangeDefault"
                             class="payment__table"
                             @edititem="editPayment"
                             @deleteitem="onPaymentMethodDeleteClick"
                             @setitemasdefault="setDefaultInvoice" />
        </div>
        <div v-if="paymentMethods.length && departments.length"
             class="payment__payment-methods">
          <div class="payment__table-title-wrapper">
            <h3 class="payment__table-title">{{ departmentsPaymentsTitle }}</h3>
            <button class="sk-btn sk-btn--default payment__table-btn"
                    @click="openAddDepartmentInvoiceDialog">{{ $gettext('Add new') }}</button>
          </div>
          <ent-setting-table v-if="departmentsPaymentsList.length"
                             :items-list="departmentsPaymentsList"
                             :cols-list="departmentsPaymentsCols"
                             :can-change="true"
                             class="payment__table"
                             @edititem="editDepartmentPayment"
                             @deleteitem="confirmRemovalDepartmentInvoice" />
          <div v-else
               class="payment__no-items-text">{{ $gettext('No custom methods') }}</div>
        </div>
      </section>
    </page-data-section>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import EnterpriseSettingTable from '@/components/enterprise/shared/EnterpriseSettingTable';
  import DefaultPaymentMethodTable from '@/components/enterprise/shared/DefaultPaymentMethodTable';
  import PaymentMethodOptions from '@/components/payments_components/modals/PaymentMethodOptionModal';

  export default {
    asyncData({store}) {
      let promiseArr = '';
      const options = {
        verbose: true
      };
      if (!store.state.PaymentPayoutStore.paymentMethods) {
        promiseArr = [...promiseArr, store.dispatch('PaymentPayoutStore/getPayments')];
      }
      if (store.getters['UserInfoStore/userIsEnterpriseMember']) {
        promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/getDepartments', options)];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'payment-method-options': PaymentMethodOptions,
      'page-data-section': PageDataSection,
      'ent-setting-table': EnterpriseSettingTable,
      'default-payment-table': DefaultPaymentMethodTable
    },
    data() {
      return {
        progressActive: false,
        progressPaymentsActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember',
        'enterpriseName',
        'userIsAdminOfEnterprise'
      ]),
      ...mapState('EnterpriseStore', {
        departments: ({departments}) => departments || []
      }),
      ...mapState('PaymentPayoutStore', {
        paymentMethods: ({paymentMethods}) => paymentMethods || []
      }),
      canChangeDefault() { return this.userIsAdminOfEnterprise || this.$isGodmode(); },
      sectionTitle() { return this.$gettext('Payment methods'); },
      defaultPaymentMethodTitle() {
        const template = this.$gettext('%{enterpriseName} - Default payment method');
        return this.$gettextInterpolate(template, {enterpriseName: this.enterpriseName});
      },
      allPaymentMethodsTitle() {
        const template = this.$gettext('%{enterpriseName} - All payment methods');
        return this.$gettextInterpolate(template, {enterpriseName: this.enterpriseName});
      },
      defaultPaymentMethod() {
        return this.paymentsList.find((paymentMethod) => paymentMethod.default === true);
      },
      paymentMethodsExcludeDefault() {
        return this.paymentsList.filter((paymentMethod) => !paymentMethod.default);
      },
      paymentsList() {
        return this.paymentMethods.map((method = {}) => {
          const createdAt = this.$moment(method.createdAt);
          const invoiceInfoTitles = this.getInvoiceInfoTitles(method);
          const groupStrategy = helpers.invoiceInfo.invoiceGroupStrategy(this, method.invoiceGroupStrategy || 'per_job');

          method.createdAtText = createdAt.format('DD/MM/YYYY HH:mm');
          method.name = method.orgName ? `${method.orgName} / ${method.orgNumber} / ${groupStrategy}` : `${method.email || 'Org.:' + method.orgNumber} / ${groupStrategy}`;

          return {
            ...method,
            ...invoiceInfoTitles
          };
        }) || [];
      },
      activePaymentsList() {
        return this.paymentsList.filter((payment) => !payment.archived) || [];
      },
      departmentsPaymentsList() {
        const payments = [];

        this.departments
          .forEach((department) => {
            const payment = this.activePaymentsList.find((payment) => payment.id === department.paymentMethod?.id) || '';
            const invoiceInfoTitles = this.getInvoiceInfoTitles(payment);

            if (payment) {
              const departmentPayment = {
                ...payment,
                ...invoiceInfoTitles,
                departmentId: department.id,
                departmentName: department.name
              };
              payments.push(departmentPayment);
            }
          });

        return payments;
      },
      paymentsColsList() {
        return [{
          key: 'orgName',
          name: this.$gettext('Organization name')
        }, {
          key: 'orgNumber',
          name: this.$gettext('Organization number')
        }, {
          key: 'email',
          name: this.$gettext('Invoice email address')
        }, {
          key: 'invoiceInfo',
          name: [
            this.$gettext('Invoice group strategy'),
            this.$gettext('Invoice frequency'),
            this.$gettext('Invoice delivery mechanism'),
          ].join('<br>'),
          showOn: 'desktop',
          condition: this.$isGodmode()
        }, {
          key: 'invoiceStrategyGroupText',
          name: this.$gettext('Invoice group strategy'),
          showOn: this.$isGodmode() ? 'mobile' : undefined
        }, {
          key: 'invoiceFrequencyText',
          name: this.$gettext('Invoice frequency'),
          showOn: 'mobile',
          condition: this.$isGodmode()
        }, {
          key: 'invoiceDeliveryMechanizmText',
          name: this.$gettext('Invoice delivery mechanism'),
          showOn: 'mobile',
          condition: this.$isGodmode()
        }, {
          key: 'createdAtText',
          name: this.$gettext('Date of creation')
        }];
      },
      paymentsCols() {
        return this.getColsList(this.paymentsColsList);
      },
      departmentsPaymentsColsList() {
        return [{
          key: 'orgName',
          name: this.$gettext('Organization name')
        }, {
          key: 'departmentName',
          name: this.$gettext('Department')
        }, {
          key: 'orgNumber',
          name: this.$gettext('Organization number')
        }, {
          key: 'invoiceInfo',
          name: [
            this.$gettext('Invoice group strategy'),
            this.$gettext('Invoice frequency'),
            this.$gettext('Invoice delivery mechanism'),
          ].join('<br>'),
          showOn: 'desktop',
          condition: this.$isGodmode()
        }, {
          key: 'invoiceStrategyGroupText',
          name: this.$gettext('Invoice group strategy'),
          showOn: this.$isGodmode() ? 'mobile' : undefined
        }, {
          key: 'invoiceFrequencyText',
          name: this.$gettext('Invoice frequency'),
          showOn: 'mobile',
          condition: this.$isGodmode()
        }, {
          key: 'invoiceDeliveryMechanizmText',
          name: this.$gettext('Invoice delivery mechanism'),
          showOn: 'mobile',
          condition: this.$isGodmode()
        }, {
          key: 'createdAtText',
          name: this.$gettext('Date of creation')
        }];
      },
      departmentsPaymentsCols() {
        return this.getColsList(this.departmentsPaymentsColsList);
      },
      defaultItemText() {
        return this.$gettext('Default payment method');
      },
      departmentsPaymentsTitle() {
        return this.$gettext('Custom methods for specific departments');
      }
    },
    methods: {
      getColsList(colsList = []) {
        return colsList.filter((col) => {
          return col.condition !== undefined ? col.condition : true;
        });
      },
      getInvoiceInfoTitles({
        invoiceGroupStrategy,
        invoiceFrequency,
        invoiceDeliveryMechanizm
      } = {
        invoiceGroupStrategy: 'per_job',
        invoiceFrequency: 'fortnightly',
        invoiceDeliveryMechanizm: 'ehf'
      }) {
        const invoiceStrategyGroupText = helpers.invoiceInfo.invoiceGroupStrategy(this, invoiceGroupStrategy) || '-';
        const invoiceFrequencyText = helpers.invoiceInfo.invoiceFrequency(this, invoiceFrequency) || '-';
        const invoiceDeliveryMechanizmText = helpers.invoiceInfo.invoiceDeliveryMechanizm(this, invoiceDeliveryMechanizm) || '-';

        return {
          invoiceStrategyGroupText,
          invoiceFrequencyText,
          invoiceDeliveryMechanizmText,
          invoiceInfo: [
            invoiceStrategyGroupText,
            invoiceFrequencyText,
            invoiceDeliveryMechanizmText
          ].join('<br>')
        };
      },
      editDepartmentPayment(currentInvoice) {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-department-invoice-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Edit invoice information for specific department'),
            context: this,
            departmentsList: this.departments,
            paymentsList: this.activePaymentsList,
            currentInvoice
          }
        });
      },
      editPayment(currentPayment) {
        const type = currentPayment.methodType === 'individual' ? 'email' : 'ehf';
        this.$store.commit('ModalStore/setModal', {
          component: 'add-invoice-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Edit invoice information'),
            context: this,
            type: type,
            currentPayment
          }
        });
      },
      onPaymentMethodDeleteClick(id) {
        const activePaymentMethodCount = this.paymentMethods.filter((method) => !method.archived)?.length;
        const isDefaultPaymentMethod = this.paymentMethods.find((method) => method.id === id).default;
        if (isDefaultPaymentMethod) {
          this.openDeleteInfoModal({
            id,
            title: this.$gettext('Default payment method'),
            message: this.$gettext('Sorry, you can\'t delete the default payment method. Please choose another payment method as default to delete this one.')
          });
        } else if (activePaymentMethodCount <= 1) {
          this.openDeleteInfoModal({
            id,
            title: this.$gettext('Last payment method'),
            message: this.$gettext('Sorry, you can\'t delete the last payment method. Please add another payment method to delete this one.')
          });
        } else this.confirmRemovalInvoice(id);
      },
      openDeleteInfoModal({id, title, message}) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          data: {
            title: title,
            context: this,
            callbackParams: id,
            text: message,
            btnTexts: {
              cancelBtnText: this.$gettext('Ok')
            },
            visibility: {
              hideActionBtn: true
            }
          }
        });
      },
      confirmRemovalInvoice(id) {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          data: {
            title: this.$gettext('Archive payment method?'),
            context: this,
            modalCallback: this.deletePaymentInvoice,
            callbackParams: id,
            btnTexts: {
              actionBtnText: this.$gettext('Archive')
            },
          }
        });
      },
      confirmRemovalDepartmentInvoice(id) {
        const departmentId = this.departmentsPaymentsList.find((payment) => payment.id === id)?.departmentId || '';
        if (this.departmentsPaymentsList.length === 1) {
          this.$store.commit('InfoModalStore/setInfoModal', {
            title: this.$gettext('Delete payment method'),
            text: this.$gettext('Sorry, you can\'t delete the payment method from specific departments. Please add another payment method to delete this one.')
          });
        } else {
          this.$store.commit('ModalStore/setModal', {
            component: 'confirmation-modal',
            width: 410,
            data: {
              title: this.$gettext('Remove payment method?'),
              context: this,
              modalCallback: this.deleteDepartmentPaymentInvoice,
              callbackParams: departmentId,
              text: this.$gettext('Are you sure you want to delete this payment method from specific department?'),
              btnTexts: {
                actionBtnText: this.$gettext('Delete')
              }
            }
          });
        }
      },
      openAddDepartmentInvoiceDialog() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-department-invoice-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Add invoice information for specific department'),
            context: this,
            departmentsList: this.departments,
            paymentsList: this.activePaymentsList
          }
        });
      },
      setDefaultInvoice(id) {
        const form = new FormData();

        form.append('payment_method[default]', true);
        this.progressPaymentsActive = true;
        this.$store.dispatch('PaymentPayoutStore/updatePayment', {paymentId: id, form}).then(() => {
          this.progressPaymentsActive = false;
        }).catch((error) => {
          this.progressPaymentsActive = false;
          if (error?.status == 401) {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
              this.$store.dispatch('ModalStore/closeModal');
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('Sorry you don\'t have rights to manage payment methods. Please contact an administrator.')
                });
              }, 0);
            });
          } else {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            }, 0);
          }
        });
      },
      deletePaymentInvoice(id) {
        const form = new FormData();

        form.append('payment_method[archived]', true);
        this.progressActive = true;
        this.$store.dispatch('PaymentPayoutStore/deletePayment', {paymentId: id, form}).then(() => {
          this.progressActive = false;
        }).catch((error) => {
          this.handlePaymentDetailsDeletionError(error);
        });
      },
      deleteDepartmentPaymentInvoice(id) {
        const form = new FormData();

        form.append('department[default_payment_method_id]', null);
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/updateDepartment', {departmentId: id, form}).then(() => {
          this.progressActive = false;
        }).catch((error) => {
          this.handlePaymentDetailsDeletionError(error);
        });
      },
      handlePaymentDetailsDeletionError(error) {
        const errors = error?.data?.errors;
        if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
            this.progressActive = false;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry you don\'t have rights to manage payment methods. Please contact an administrator.')
              });
            }, 0);
          });
        } else if (errors?.payment_method?.includes('Cannot remove last payment method with active charges')) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
            this.progressActive = false;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('You can\'t remove your payment details unless you have no more active assignments.')
              });
            }, 0);
          });
        } else if (errors?.includes('Department\'s default payment method cannot be archived') != -1) {
          this.progressActive = false;
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('The payment method you\'re trying to archive is set as default for a department(s). In order to archive this payment method please remove it from all departments where it set as default first.')
            });
          });
        } else {
          this.progressActive = false;
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('PaymentPayoutStore/removePayments');
      next();
    }
  };
</script>

<style>
  .payment .ent-settings-table__col--invoiceInfo,
  .payment .ent-settings-table-row__col--invoiceInfo {
    min-width: 150px;
  }

  .page-data-section.payment__settings {
    padding-bottom: 0;
  }

  .payment__table {
    width: calc(100% + 40px);
    margin: 15px -20px 0;
  }

  .payment__table--default {
    margin-bottom: 15px;
  }

  .payment__table-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .payment__table-btn {
    width: auto;
    min-width: 90px;
    padding: 0 10px;
  }

  .payment-options > .payment-method_type {
    margin-top: 0 !important;
  }
</style>

<style scoped>
.payment {
  display: block;
  width: 760px;
}

.private-customer-payment {
  margin: 20px auto;
}

.payment__content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.payment-options {
  justify-content: flex-start !important;
}

.payment__payment-methods {
  width: calc(100% + 40px);
  min-height: 35px;
  margin: 0 -20px;
  padding: 10px 20px 0;
  border-top: 20px solid #e9ebef;
}

.payment__table-title {
  font-size: 14px;
}

.payment__no-items-text {
  width: 100%;
  padding-bottom: 10px;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 768px) {
  .payment {
    width: 100%;
    margin: 0;
    padding-bottom: 70px;
  }

  .payment__content-wrapper {
    justify-content: center;
  }
}
</style>

<style src="@assets/css/options.css"></style>
<style src="@assets/css/page-data-section.css"></style>
